<template>
  <b-custom-tabs :tabs="tabs" lazy>
    <template #activeCompanies> <active-company-list ref="activeCompanies" @companyArchived="companyArchived" /> </template>
    <template #archivedCompanies> <archived-companies @revokedFromArchive="revokedFromArchive" ref="archivedCompanies" /> </template>
  </b-custom-tabs>
</template>

<script>
import ActiveCompanyList from "./ActiveCompanyList.vue";
import ArchivedCompanies from "./ArchivedCompanies.vue";
import CustomerList from "@/views/Users/Customers/CustomerList.vue";
import locations from "../details/info/locations.vue";

export default {
  components: {
    ActiveCompanyList,
    ArchivedCompanies,
  },
  data() {
    return {
      tabs: [
        { key: "activeCompanies", title: "Aktif" },
        { key: "archivedCompanies", title: "Pasif" },
        { key: "customers", title: "Firma Kullanıcıları", component: CustomerList },
        { title: "Konumlar ve Adresler", component: locations },
      ],
    };
  },
  methods: {
    companyArchived() {
      const archivedCompanies = this.$refs.archivedCompanies;
      if (archivedCompanies) archivedCompanies.getCompanyList();
    },
    revokedFromArchive() {
      const activeCompanies = this.$refs.activeCompanies;
      if (activeCompanies) activeCompanies.getCompanyList();
    },
  },
};
</script>

<style></style>
