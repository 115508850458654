<template>
  <app-overlay>
    <db-searchable-table v-model="filters" :items="items" :total-rows="totalRows" :fields="fields" hover searchable @row-clicked="details" @changed="getCompanyList">
      <template #name="{ item }">
        <span v-if="item.name.length > 20" v-b-tooltip.hover.top="item.name">{{ $fn(item.name, 20) }}</span>
        <span v-else>
          {{ item.name }}
        </span>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="revokeFromArchive(item)"> <feather-icon icon="ArchiveIcon" /> Arşivden Geri Al </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      totalRows: 0,
      filters: {},
      fields: [
        {
          key: "name",
          label: "Firma Unvanı",
          sortable: true,
        },
        {
          key: "branchCount",
          label: "Siciller",
          sortable: true,
        },
        {
          key: "employeeCount",
          label: "Çalışanlar",
          sortable: true,
        },
        {
          key: "naceCode",
          label: "Nace",
          sortable: true,
        },
        {
          key: "isCustomerFree",
          label: "Tür",
          sortable: true,
        },
        {
          key: "actions",
          label: "Eylemler",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    details(company) {
      this.$router.push({
        name: "company-details",
        params: { id: company?._id },
      });
    },
    getCompanyList() {
      this.$axios
        .get("/company/paginated-list", {
          params: { ...this.filters, isArchived: true, searchFields: JSON.stringify(["name"]), populates: JSON.stringify(["branchCount", "employeeCount"]) },
          loading: "table",
        })
        .then((result) => {
          this.items = result.data.data;
          this.totalRows = result.data.total;
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata oluştu",
          });
        });
    },
    revokeFromArchive(company) {
      this.$confirm({ message: "Firma arşivden geri alınacaktır onaylıyor musunuz?" }, () => {
        this.$axios
          .patch(`/company/${company._id}/revoke-archive`)
          .then((result) => {
            this.getCompanyList();
            this.$emit("revokedFromArchive");
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Firma başarıyla arşivlendi.",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  mounted() {
    this.getCompanyList();
  },
};
</script>

<style></style>
