<template>
  <validation-provider ref="validationProvider" name="Konum" #default="{ errors, validate }" rules="required">
    <div :class="errors.length ? 'border-primary p-50 rounded' : ''">
      <GmapMap
        id="map"
        ref="mapRef"
        :options="{
          zoomControl: true,
          fullscreenControl: true,
          clickableIcons: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          disableDefaultUi: false,
        }"
        :center="mapOptions.center"
        :zoom="mapOptions.zoom"
        style="width: 100%; height: 45vh"
        @click="selectedLocation($event, validate)"
      >
        <GmapMarker v-if="location.lat && location.lng" :position="location" />
      </GmapMap>
    </div>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: { ValidationProvider },
  data() {
    return {
      location: { lat: null, lng: null },
    };
  },
  computed: {
    mapOptions() {
      return {
        center: { lat: 38.963745, lng: 35.243322 },
        zoom: 5.9,
      };
    },
  },
  methods: {
    selectedLocation(event, validate) {
      const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.setLocation(location);
      this.$emit("input", location);
      validate(this.location);
    },
    setLocation(loc, zoom) {
      this.$nextTick(() => {
        this.$refs?.mapRef?.$mapPromise.then((map) => {
          map.panTo(loc);
          zoom && map.setZoom(18);
        });
        this.location = loc;
        this?.$refs?.validationProvider?.validate(this.location);
      });
    },
    getCurrentLocation() {
      this.$emit("loading", true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setLocation(
              {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              true
            );
            this.$emit("loading", false);
          },
          (error) => {
            this.$emit("loading", false);
            console.error("Error getting location: ", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
  },
};
</script>

<style></style>
