<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      :items="items"
      :total-rows="totalRows"
      :fields="companyFields"
      hover
      searchable
      @row-clicked="details"
      @changed="getCompanyList"
      use-route-query
    >
      <template #modalbutton>
        <b-dropdown split class="flex-1-md" size="md" text="Primary" variant="primary" no-caret @click="$router.push({ name: 'new-company' })">
          <template #button-content>
            <span class="fs-14"> Firma Ekle </span>
          </template>
          <b-dropdown-item>
            <span class="d-flex align-items-center gap-10"><feather-icon icon="UploadIcon" size="13" /> Toplu Ekle </span>
          </b-dropdown-item>
          <b-dropdown-item>
            <span class="d-flex align-items-center gap-10"><feather-icon icon="UploadIcon" size="13" /> ISG Katipten Ekle </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #name="{ item }">
        <span v-if="item.name.length > 20" v-b-tooltip.hover.top="item.name">{{ $fn(item.name, 20) }}</span>
        <span v-else>
          {{ item.name }}
        </span>
      </template>
      <template #wsExpertAssignments="{ item }">
        <b-badge class="p-50" v-if="item.wsExpertAssignments && item.wsExpertAssignments.physicianConfirm" :variant="$variants[item.wsExpertAssignments.physicianConfirm]" pill>
          <feather-icon size="25" :icon="getIcon(item, 'wsExpertAssignments', 'physicianConfirm')" />
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge class="p-50" v-if="item.wsExpertAssignments && item.wsExpertAssignments.companyConfirm" :variant="$variants[item.wsExpertAssignments.companyConfirm]" pill>
          <feather-icon :icon="getIcon(item, 'wsExpertAssignments', 'companyConfirm')" />
        </b-badge>
        <span v-else>-</span>
      </template>
      <template #physicianAssignments="{ item }">
        <b-badge class="p-50" v-if="item.physicianAssignments && item.physicianAssignments.physicianConfirm" :variant="$variants[item.physicianAssignments.physicianConfirm]" pill>
          <feather-icon :icon="getIcon(item, 'physicianAssignments', 'physicianConfirm')" />
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge class="p-50" v-if="item.physicianAssignments && item.physicianAssignments.companyConfirm" :variant="$variants[item.physicianAssignments.companyConfirm]" pill>
          <feather-icon :icon="getIcon(item, 'physicianAssignments', 'companyConfirm')" />
        </b-badge>
        <span v-else>-</span>
      </template>
      <template #row-details="data">
        <branch-list :company="data.item._id" />
      </template>
      <template #branchCount="row">
        <div class="d-flex align-items-center gap-10">
          {{ row.item.branchCount }}
          <div>
            <b-button v-if="row.item.branchCount > 1" variant="gradient-secondary" class="btn-icon rounded-circle p-0 p-50" @click="toggleDetails(row)">
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
          </div>
        </div>
      </template>
      <template #isCustomerFree="{ item }">
        <b-badge :variant="item.isCustomerFree ? 'secondary' : 'success'">
          {{ item.isCustomerFree ? "Ücretsiz" : "Sözleşmeli" }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="archiveCompany(item)"> <feather-icon icon="ArchiveIcon" /> Arşivle </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import BranchList from "./BranchList.vue";
export default {
  components: {
    BranchList,
  },
  data() {
    return {
      items: [],
      mountedRows: [],
      filters: {},
      companyFields: [
        {
          key: "name",
          label: "Firma Unvanı",
          sortable: true,
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "branchCount",
          label: "Sicil",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "employeeCount",
          label: "Çalışan",

          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "naceCode",
          label: "Nace",

          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "wsExpertAssignments",
          label: "İGU / Firma",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "physicianAssignments",
          label: "İH / Firma",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "createdAt",
          label: "Kayıt Tarihi",
          formatDate: true,
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "sozlesme",
          label: "Sözleşme Tarihi",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "songiris",
          label: "Son Giriş",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "memnuniyet",
          label: "Memnuniyet",
        },
        { key: "actions", label: "Eylemler" },
      ],
      totalRows: 0,
    };
  },
  computed: {
    companies() {
      return this.$store.getters.companies;
    },
  },

  methods: {
    toggleDetails(row) {
      row.toggleDetails();
    },
    getIcon(item, field, confirmType) {
      const icons = {
        onaylandı: "CheckIcon",
        onayda: "ClockIcon",
        onaylanmadı: "XIcon",
        reddedildi: "XIcon",
      };
      if (item[field][confirmType]) return icons[item[field][confirmType]];
    },
    getCompanyList() {
      this.$axios
        .get("/company/paginated-list", {
          params: { ...this.filters, isArchived: false, searchFields: JSON.stringify(["name"]), populates: JSON.stringify(["branchCount", "employeeCount", "assignments"]) },
          loading: "table",
        })
        .then((result) => {
          this.items = result.data.data;
          this.totalRows = result.data.total;
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata oluştu",
          });
        });
    },
    details(company) {
      this.$router.push({
        name: "company-details",
        params: { id: company?._id },
      });
    },
    archiveCompany(company) {
      this.$confirm({ message: "Firmayı arşivlemek istediğinize emin misiniz?" }, () => {
        this.$axios
          .patch(`/company/${company._id}/archive`)
          .then(() => {
            this.getCompanyList();
            this.$emit("companyArchived");
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Firma başarıyla arşivlendi.",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  destroyed() {
    this.$store.commit("setCompanies", []);
  },
};
</script>
