<template>
  <b-card class="border" body-class="p-1">
    <div class="d-flex">
      <app-button @click="showLocationFormModal" size="md" class="flex-1-md" text="Konum Ekle" icon="PlusIcon" />
    </div>
    <hr />
    <b-alert variant="warning" :show="showAlert">
      <div class="alert-body">
        <feather-icon class="mr-50" icon="InfoIcon" />
        <span>Henüz konum eklenmemiştir. </span>
      </div>
    </b-alert>
    <b-list-group v-if="items.length" class="my-1 border-secondary rounded">
      <b-list-group-item class="p-0">
        <GmapMap
          id="map"
          ref="mapRef"
          :options="{
            zoomControl: true,
            fullscreenControl: true,
            clickableIcons: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            disableDefaultUi: false,
          }"
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 45vh"
        >
          <GmapMarker v-for="(item, key) in items" :key="key" :position="item" @click="(isWindowOpen = true), (windowItem = item), setLocation(item)" />
          <gmap-info-window v-if="windowItem" :position="windowItem" :opened="isWindowOpen" @closeclick="closeClick">
            <div>
              <h5 class="m-0 text-center">
                {{ windowItem.title }}
              </h5>
              <hr class="my-50" />
              <span v-if="!selectedCompany">
                {{ windowItem.company.name }}
                <hr class="my-50" />
              </span>
              <app-button @click="goToLocation(windowItem)" text="Konuma Git" icon="MapPinIcon" block />
            </div>
          </gmap-info-window>
        </GmapMap>
      </b-list-group-item>
      <b-list-group-item v-for="item in items" :key="item._id">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <h5>{{ item.title }}</h5>
            <small v-if="!selectedCompany">{{ item.company.name }}</small>
          </div>
          <div class="d-flex gap-5 justify-content-end flex-1">
            <app-button @click="goToLocation(item)" text="Konuma Git" />
            <app-button text="Güncelle" />
            <app-button text="Sil" />
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import LocationForm from "./LocationForm.vue";

export default {
  data() {
    return {
      items: [],
      center: { lat: 38.963745, lng: 35.243322 },
      zoom: 6,
      isWindowOpen: false,
      windowItem: null,
      icon: require("@/assets/images/building.png"),
      showAlert: false,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
    branchLocations() {
      return this.$store.getters?.branchLocations;
    },
    google() {
      return new gmapApi();
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/location/list", { params: { company: this?.selectedCompany?._id, populates: JSON.stringify(["company"]) } })
        .then((result) => {
          this.items = result?.data;
          this.showAlert = !this.items?.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showLocationFormModal() {
      this.$showAppModal({ title: "Yeni Konum", component: LocationForm });
    },
    setLocation(loc, zoom = 16) {
      this.$refs?.mapRef?.$mapPromise.then((map) => {
        map.setCenter(loc);
        map.setZoom(zoom);
      });
    },
    goToLocation(item) {
      if (item?.lat && item?.lng) {
        window.open(`http://maps.google.com?q=${item?.lat},${item?.lng}`, "_blank");
      }
    },
    getBounds() {
      this.$nextTick(() => {
        if (this?.google?.maps?.LatLngBounds) {
          const bounds = new this.google.maps.LatLngBounds();
          for (const item of this.items) {
            bounds.extend(new google.maps.LatLng(item.lat, item.lng));
          }
          this.$refs?.mapRef?.$mapPromise?.then(() => {
            this.$refs?.mapRef?.$mapObject?.fitBounds(bounds);
          });
        }
      });
    },
    closeClick() {
      this.isWindowOpen = false;
      this.windowItem = null;
      this.items.length > 1 ? this.getBounds() : this.setLocation(this.items[0]);
    },
  },
  watch: {
    google(val) {
      if (val.maps) {
        this.items.length > 1 && this.getBounds();
      }
    },
    items(val) {
      if (val.length > 0) {
        val.length === 1 ? this.setLocation(val[0]) : this.getBounds();
      } else {
        this.setLocation(this.center, 6);
      }
    },
  },
  mounted() {
    this.google;
    if (this.google.maps) {
      this.getBounds();
    }
    this.getData();
  },
};
</script>
