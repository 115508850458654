<template>
  <validation-observer ref="locationForm">
    <company-select :reduce="(option) => option._id" v-model="company" required />
    <app-input v-model="title" name="Konum Adı" label="Konum Adı:" placeholder="Konum Adı Giriniz..." rules="required" />
    <create-and-update-map @loading="isLoading = $event" ref="createAndUpdateMap" v-model="location" />
    <hr />
    <b-row style="gap: 10px 0">
      <b-col md="6">
        <app-button size="md" mdiIcon="CrosshairsGps" text="Mevcut Konum" @click="currentLocation" block :loading="isLoading" />
      </b-col>
      <b-col md="6">
        <app-button size="md" icon="PlusIcon" text="Ekle" @click="newLocation" block />
      </b-col>
    </b-row>
    <hr />
  </validation-observer>
</template>

<script>
import CreateAndUpdateMap from "./CreateAndUpdateMap.vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver, CreateAndUpdateMap },
  data() {
    return {
      title: "",
      location: null,
      company: null,
      isLoading: false,
    };
  },
  methods: {
    newLocation() {
      this.$validate(this.$refs?.locationForm, () => {});
    },
    currentLocation() {
      this?.$refs?.createAndUpdateMap.getCurrentLocation();
    },
  },
};
</script>

<style></style>
