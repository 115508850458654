var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',[_c('db-searchable-table',{attrs:{"items":_vm.items,"total-rows":_vm.totalRows,"fields":_vm.companyFields,"hover":"","searchable":"","use-route-query":""},on:{"row-clicked":_vm.details,"changed":_vm.getCompanyList},scopedSlots:_vm._u([{key:"modalbutton",fn:function(){return [_c('b-dropdown',{staticClass:"flex-1-md",attrs:{"split":"","size":"md","text":"Primary","variant":"primary","no-caret":""},on:{"click":function($event){return _vm.$router.push({ name: 'new-company' })}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"fs-14"},[_vm._v(" Firma Ekle ")])]},proxy:true}])},[_c('b-dropdown-item',[_c('span',{staticClass:"d-flex align-items-center gap-10"},[_c('feather-icon',{attrs:{"icon":"UploadIcon","size":"13"}}),_vm._v(" Toplu Ekle ")],1)]),_c('b-dropdown-item',[_c('span',{staticClass:"d-flex align-items-center gap-10"},[_c('feather-icon',{attrs:{"icon":"UploadIcon","size":"13"}}),_vm._v(" ISG Katipten Ekle ")],1)])],1)]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
return [(item.name.length > 20)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.name),expression:"item.name",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm.$fn(item.name, 20)))]):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"wsExpertAssignments",fn:function(ref){
var item = ref.item;
return [(item.wsExpertAssignments && item.wsExpertAssignments.physicianConfirm)?_c('b-badge',{staticClass:"p-50",attrs:{"variant":_vm.$variants[item.wsExpertAssignments.physicianConfirm],"pill":""}},[_c('feather-icon',{attrs:{"size":"25","icon":_vm.getIcon(item, 'wsExpertAssignments', 'physicianConfirm')}})],1):_c('span',[_vm._v("-")]),_vm._v(" / "),(item.wsExpertAssignments && item.wsExpertAssignments.companyConfirm)?_c('b-badge',{staticClass:"p-50",attrs:{"variant":_vm.$variants[item.wsExpertAssignments.companyConfirm],"pill":""}},[_c('feather-icon',{attrs:{"icon":_vm.getIcon(item, 'wsExpertAssignments', 'companyConfirm')}})],1):_c('span',[_vm._v("-")])]}},{key:"physicianAssignments",fn:function(ref){
var item = ref.item;
return [(item.physicianAssignments && item.physicianAssignments.physicianConfirm)?_c('b-badge',{staticClass:"p-50",attrs:{"variant":_vm.$variants[item.physicianAssignments.physicianConfirm],"pill":""}},[_c('feather-icon',{attrs:{"icon":_vm.getIcon(item, 'physicianAssignments', 'physicianConfirm')}})],1):_c('span',[_vm._v("-")]),_vm._v(" / "),(item.physicianAssignments && item.physicianAssignments.companyConfirm)?_c('b-badge',{staticClass:"p-50",attrs:{"variant":_vm.$variants[item.physicianAssignments.companyConfirm],"pill":""}},[_c('feather-icon',{attrs:{"icon":_vm.getIcon(item, 'physicianAssignments', 'companyConfirm')}})],1):_c('span',[_vm._v("-")])]}},{key:"row-details",fn:function(data){return [_c('branch-list',{attrs:{"company":data.item._id}})]}},{key:"branchCount",fn:function(row){return [_c('div',{staticClass:"d-flex align-items-center gap-10"},[_vm._v(" "+_vm._s(row.item.branchCount)+" "),_c('div',[(row.item.branchCount > 1)?_c('b-button',{staticClass:"btn-icon rounded-circle p-0 p-50",attrs:{"variant":"gradient-secondary"},on:{"click":function($event){return _vm.toggleDetails(row)}}},[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon"}})],1):_vm._e()],1)])]}},{key:"isCustomerFree",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.isCustomerFree ? 'secondary' : 'success'}},[_vm._v(" "+_vm._s(item.isCustomerFree ? "Ücretsiz" : "Sözleşmeli")+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('app-dropdown',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.archiveCompany(item)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}}),_vm._v(" Arşivle ")],1)],1)]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }