<template>
  <b-card body-class="p-0">
    <db-searchable-table v-model="filters" :items="items" :fields="branchFields" :total-rows="total" no-border no-body-gap>
      <template #wsExpertAssignment="{ item }">
        <b-badge class="p-50" v-if="item.wsExpertAssignment && item.wsExpertAssignment.physicianConfirm" :variant="$variants[item.wsExpertAssignment.physicianConfirm]" pill>
          <feather-icon size="25" :icon="getIcon(item, 'wsExpertAssignment', 'physicianConfirm')" />
          <!-- {{ $capitalize(item.wsExpertAssignment.physicianConfirm) }} -->
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge class="p-50" v-if="item.wsExpertAssignment && item.wsExpertAssignment.companyConfirm" :variant="$variants[item.wsExpertAssignment.companyConfirm]" pill>
          <feather-icon :icon="getIcon(item, 'wsExpertAssignment', 'companyConfirm')" />
          <!-- {{ $capitalize(item.wsExpertAssignments.companyConfirm) }} -->
        </b-badge>
        <span v-else>-</span>
      </template>
      <template #physicianAssignment="{ item }">
        <b-badge class="p-50" v-if="item.physicianAssignment && item.physicianAssignment.physicianConfirm" :variant="$variants[item.physicianAssignment.physicianConfirm]" pill>
          <feather-icon :icon="getIcon(item, 'physicianAssignment', 'physicianConfirm')" />
          <!-- {{ $capitalize(item.physicianAssignment.physicianConfirm) }} -->
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge class="p-50" v-if="item.physicianAssignment && item.physicianAssignment.companyConfirm" :variant="$variants[item.physicianAssignment.companyConfirm]" pill>
          <!-- {{ $capitalize(item.physicianAssignment.companyConfirm) }} -->
          <feather-icon :icon="getIcon(item, 'physicianAssignment', 'companyConfirm')" />
        </b-badge>
        <span v-else>-</span>
      </template>
      <!-- <template #wsExpertAssignment="{ item }">
        <b-badge v-if="item.wsExpertAssignment" :variant="$variants[item.wsExpertAssignment.physicianConfirm]">
          {{ $capitalize(item.wsExpertAssignment.physicianConfirm) }}
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge v-if="item.wsExpertAssignment" :variant="$variants[item.wsExpertAssignment.companyConfirm]">
          {{ $capitalize(item.wsExpertAssignment.companyConfirm) }}
        </b-badge>
        <span v-else>-</span>
      </template>
      <template #physicianAssignment="{ item }">
        <b-badge v-if="item.physicianAssignment" :variant="$variants[item.physicianAssignment.physicianConfirm]">
          {{ $capitalize(item.physicianAssignment.physicianConfirm) }}
        </b-badge>
        <span v-else>-</span>
        /
        <b-badge v-if="item.physicianAssignment" :variant="$variants[item.physicianAssignment.companyConfirm]">
          {{ $capitalize(item.physicianAssignment.companyConfirm) }}
        </b-badge>
        <span v-else>-</span>
      </template> -->
    </db-searchable-table>
  </b-card>
</template>

<script>
export default {
  props: {
    company: String,
  },

  data() {
    return {
      filters: {},
      items: [],
      total: 0,
      branchFields: [
        {
          key: "name",
          label: "Sicil Adı",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "recordNo",
          label: "Sicil No",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "employeeCount",
          label: "Çalışanlar",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "wsExpertAssignment",
          label: "İGU / Firma",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
        {
          key: "physicianAssignment",
          label: "İH / Firma",
          thClass: "no-wrap",
          tdClass: "no-wrap",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getData() {
      this.$axios
        .get("/company/branches/list/paginated", { params: { ...this.filters, company: this.company, populates: JSON.stringify(["physicianAssignment", "wsExpertAssignment"]) } })
        .then((result) => {
          this.items = result.data.data;
          this.total = result.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIcon(item, field, confirmType) {
      const icons = {
        onaylandı: "CheckCircleIcon",
        onayda: "ClockIcon",
        onaylanmadı: "XIcon",
        reddedildi: "XIcon",
      };
      if (item[field][confirmType]) return icons[item[field][confirmType]];
    },
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.commit("setBranches", { data: [], total: 0 });
  },
};
</script>

<style></style>
