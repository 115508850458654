var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"validationProvider",attrs:{"name":"Konum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{class:errors.length ? 'border-primary p-50 rounded' : ''},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"45vh"},attrs:{"id":"map","options":{
        zoomControl: true,
        fullscreenControl: true,
        clickableIcons: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        disableDefaultUi: false,
      },"center":_vm.mapOptions.center,"zoom":_vm.mapOptions.zoom},on:{"click":function($event){return _vm.selectedLocation($event, validate)}}},[(_vm.location.lat && _vm.location.lng)?_c('GmapMarker',{attrs:{"position":_vm.location}}):_vm._e()],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }