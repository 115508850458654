var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border",attrs:{"body-class":"p-1"}},[_c('div',{staticClass:"d-flex"},[_c('app-button',{staticClass:"flex-1-md",attrs:{"size":"md","text":"Konum Ekle","icon":"PlusIcon"},on:{"click":_vm.showLocationFormModal}})],1),_c('hr'),_c('b-alert',{attrs:{"variant":"warning","show":_vm.showAlert}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v("Henüz konum eklenmemiştir. ")])],1)]),(_vm.items.length)?_c('b-list-group',{staticClass:"my-1 border-secondary rounded"},[_c('b-list-group-item',{staticClass:"p-0"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"45vh"},attrs:{"id":"map","options":{
          zoomControl: true,
          fullscreenControl: true,
          clickableIcons: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          disableDefaultUi: false,
        },"center":_vm.center,"zoom":_vm.zoom}},[_vm._l((_vm.items),function(item,key){return _c('GmapMarker',{key:key,attrs:{"position":item},on:{"click":function($event){(_vm.isWindowOpen = true), (_vm.windowItem = item), _vm.setLocation(item)}}})}),(_vm.windowItem)?_c('gmap-info-window',{attrs:{"position":_vm.windowItem,"opened":_vm.isWindowOpen},on:{"closeclick":_vm.closeClick}},[_c('div',[_c('h5',{staticClass:"m-0 text-center"},[_vm._v(" "+_vm._s(_vm.windowItem.title)+" ")]),_c('hr',{staticClass:"my-50"}),(!_vm.selectedCompany)?_c('span',[_vm._v(" "+_vm._s(_vm.windowItem.company.name)+" "),_c('hr',{staticClass:"my-50"})]):_vm._e(),_c('app-button',{attrs:{"text":"Konuma Git","icon":"MapPinIcon","block":""},on:{"click":function($event){return _vm.goToLocation(_vm.windowItem)}}})],1)]):_vm._e()],2)],1),_vm._l((_vm.items),function(item){return _c('b-list-group-item',{key:item._id},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap"},[_c('div',[_c('h5',[_vm._v(_vm._s(item.title))]),(!_vm.selectedCompany)?_c('small',[_vm._v(_vm._s(item.company.name))]):_vm._e()]),_c('div',{staticClass:"d-flex gap-5 justify-content-end flex-1"},[_c('app-button',{attrs:{"text":"Konuma Git"},on:{"click":function($event){return _vm.goToLocation(item)}}}),_c('app-button',{attrs:{"text":"Güncelle"}}),_c('app-button',{attrs:{"text":"Sil"}})],1)])])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }